<template>
  <article v-loading="loading">
    <RouterNav :name="name"></RouterNav>
    <div class="title">
      <div class="left">
        <div class="line"></div>
        <div class="unit-name">{{name}}</div>
      </div>
    </div>
    <div class="wrapper">
      <div class="title-list" v-for="(item, index) in list" :key="item.id">
        <div class="writeLib">
          <div class="writeLibTypeName">{{item.questionsTypeName}}</div>
        </div>
        <div class="written-list" v-for="(written, wIndex) in item.writtenList" :key="written.id">
          <component
              :is="written.type === '1' ? 'MultipleChoice'
          : written.type === '2' ? 'ClozeTest'
          : written.type === '3' ? 'ReadingComprehension'
          : written.type === '4' ? 'LexicalManipulation'
          : written.type === '6' ? 'BlankFilling'
          : written.type === '7' ? 'ReadingSort'
          : written.type === '8' ? 'MultipleFilling' : ''"
              :item="written"
              :showTranslate="written.showTranslate"
              class="written"
          >
          </component>
          <div class="shop">
            <div class="left">
              <div class="tag">{{written.writeLibTypeName}}</div>
              <div class="tag" v-if="written.difficultyName">
                难度：<span :style="{color: written.difficultyColor}">{{written.difficultyName}}</span>
              </div>
              <div class="tag">
                我已布置：<span class="col-329cf3">{{written.frequency}}次</span>
              </div>
            </div>
            <div class="right">
              <div class="show-btn" @click="translateTrigger(index, wIndex)">
                <div>{{written.showTranslate ? '收起解析' : '展开解析'}}</div>
                <div :class="['icon', written.showTranslate ? 'rotate-180' : '']"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="flex" v-show="!assignType">
        <div class="col">
          <xybutton class="button" @click="writtenSelect">选题布置</xybutton>
        </div>
        <div class="col">
          <xybutton class="button button-2" @click="assignTypeChange">
            整套布置<span class="fs-12">(预计耗时：{{allCostTime}}分钟)</span>
          </xybutton>
        </div>
      </div>
      <div class="flex row" v-show="assignType === 1">
        <div>
          <span>已选：</span><span class="col-329cf3">{{writtenNum}}题</span>
          <span class="ml-28">预计耗时：</span><span class="col-329cf3">{{allCostTime}}分钟</span>
        </div>
        <xybutton class="button button-3" @click="assignTypeChange">移出清单</xybutton>
      </div>
    </div>

    <Trolley
        @listState="listState"
    ></Trolley>
  </article>
</template>

<script>
  import RouterNav from '@/components/RouterNav.vue'
  import MultipleChoice from '../components/written/MultipleChoice'
  import ClozeTest from '../components/written/ClozeTest'
  import ReadingComprehension from '../components/written/ReadingComprehension'
  import LexicalManipulation from '../components/written/LexicalManipulation'
  import BlankFilling from '../components/written/BlankFilling'
  import ReadingSort from '../components/written/ReadingSort'
  import MultipleFilling from '../components/written/MultipleFilling'
  import Trolley from './components/trolley.vue'

  export default {
    name: 'written',
    components: {
      RouterNav,
      MultipleChoice,
      ClozeTest,
      ReadingComprehension,
      LexicalManipulation,
      BlankFilling,
      ReadingSort,
      MultipleFilling,
      Trolley
    },
    data() {
      return {
        loading: false,
        id: '',
        name: '',
        list: [],
        writtenNum: 0,
        allCostTime: 0, // 整套题预计耗时
        assignType: 0 // 1：整套布置
      }
    },
    created() {
      this.id = this.$route.query.id
      this.name = this.$route.query.name
      this.indexModuleName = this.$route.query.indexModuleName
      this.moduleName = this.$route.query.moduleName
      this.moduleId = this.$route.query.moduleId
      this.getWritten()
    },
    methods: {
      listState() {
        if (!this.$store.state.selectedWorkList[this.id]) {
          this.assignType = 0
        }
      },
      assignTypeChange() {
        this.assignType = this.assignType ? 0 : 1
        if (this.assignType) {
          const obj = {
            id: this.id,
            name: this.name,
            flag: 100,
            moduleName: this.moduleName,
            indexModuleName: this.indexModuleName,
            time: this.allCostTime,
            moduleId: this.moduleId
          }
          this.$store.commit('listAdd', obj)
        } else {
          const obj = {
            id: this.id
          }
          this.$store.commit('listDel', obj)
        }
      },
      // 这个方法在这里是没用的
      writtenReview() {
        this.$router.push({
          name: 'writtenReview',
          query: {
            indexModuleName: this.indexModuleName,
            moduleName: this.moduleName,
            moduleId: this.moduleId
          }
        })
      },
      writtenSelect() {
        this.$router.push({
          name: 'writtenSelect',
          query: {
            id: this.id,
            name: '选题布置',
            writtenName: this.name,
            indexModuleName: this.indexModuleName,
            moduleName: this.moduleName,
            moduleId: this.moduleId
          }
        })
      },
      translateTrigger(index, wIndex) {
        const {list} = this
        const {writtenList} = list[index]
        writtenList[wIndex].showTranslate = !writtenList[wIndex].showTranslate
        this.$set(this.list, index, list[index])
      },
      getWritten() {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.paperQuestions,
          data: {
            id: this.id
          }
        })
          .then((response) => {
            this.loading = false
            if (response.state) {
              const {data} = response
              let writtenNum = 0
              let allCostTime = 0
              for (let i = 0, len = data.length; i < len; i += 1) {
                const {writtenList} = data[i]
                writtenNum += writtenList.length
                for (let j = 0, len = writtenList.length; j < len; j += 1) {
                  writtenList[j].time = this.common.writtenTime(writtenList[j].type, writtenList[j].libList)
                  allCostTime += writtenList[j].time
                }
              }
              this.allCostTime = allCostTime
              this.writtenNum = writtenNum
              this.list = data
            }
          })
          .catch(() => {
            this.loading = false
          })
      }
    }
  }
</script>

<style scoped lang="scss">
  .title {
    @include flex(space-between);
    background-color: #fff;
    height: 66px;
    padding-right: 28px;
    margin-bottom: 10px;

    .left {
      @include flex;
      @include font(17px, #333);

      .line {
        background-color: #309AF2;
        width: 4px;
        height: 14px;
        margin-right: 24px;
      }
    }

    .btn {
      width: 92px;
      height: 30px;
      border-color: #309AF2;
      border-radius: 15px;
      @include font(13px, #309AF2, center);
    }

    .del-btn {
      color: #FF3C30;
      border-color: #FF3C30;
    }
  }

  .wrapper {
    background-color: #fff;
    height: calc(100vh - 284px);
    overflow: auto;
    padding: 0 28px 20px;
    font-size: 17px;
    color: #333;
    position: relative;
    z-index: 90;

    .writeLib {
      .writeLibTypeName {
        height: 54px;
        line-height: 54px;
        padding-left: 14px;
        font-weight: bold;
      }
    }

    .written-list {
      border: 1px solid #E5E5E5;
      border-radius: 8px;
      margin-bottom: 10px;
      overflow: hidden;
    }

    .written {
      padding: 20px 20px 0;
    }

    .shop {
      @include flex(space-between);
      background-color: #FBFBFB;
      height: 44px;
      padding: 0 28px 0 20px;
      border-top: 1px solid #F1F1F1;

      .left,
      .right {
        display: flex;
        align-items: center;
      }

      .tag {
        background-color: #F6F6F6;
        height: 28px;
        line-height: 28px;
        padding: 0 10px;
        border: 1px solid #E5E5E5;
        border-radius: 4px;
        margin-right: 10px;
        font-size: 12px;
        text-align: center;
      }

      .show-btn {
        @include flex;
        color: #309AF2;
        font-size: 14px;
        cursor: pointer;

        .icon {
          background-image: url("../../../assets/z-1.png");
          background-size: 100% 100%;
          width: 7px;
          height: 4px;
          margin-left: 4px;
        }
      }
    }
  }

  .footer {
    background-color: #fff;
    height: 84px;
    box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, .06);
    position: relative;
    z-index: 99;

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 84px;
    }

    .row {
      padding: 0 28px;
    }

    .col {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button {
      background-color: #EAF4FD;
      width: 250px;
      height: 40px;
      border-color: #309AF2;
      font-size: 17px;
      color: #309AF2;
    }

    .button-2 {
      background: linear-gradient(to right, #6DBBFC, #309AF2);
      border-color: transparent;
      color: #fff;
    }

    .button-3 {
      background-color: #FF3C30;
      color: #fff;
      border-color: transparent;
    }
  }

  .fs-12 {
    font-size: 12px;
  }

  .ml-28 {
    margin-left: 28px;
  }
</style>
